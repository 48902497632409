;
(function($){

// Doc scroll
$(document).on('scroll', function() {
    var offset = 100;
    $('.fixed-top')[$(window).scrollTop() > offset ? 'addClass' : 'removeClass']('sticky');
    //END ready
});

//
})(jQuery);
